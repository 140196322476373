#root {
  max-width: 1280px;
  margin: 0 auto;
  /* padding: 2rem; */
  text-align: center;
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

/* old css */
.App {
  text-align: center;
  /* background-color: #303132;
  color: white; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* default css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

body {
  font-weight: 400;
  /* overflow-y: hidden; */
  position: relative;
  /* background-color: #30313285 !important;
  color: #ffffff !important; */
  /* font-family: "Centra", sans-serif !important; */
  /* -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
  font-style: normal;
  font-weight: 400;
  font-family: "Outfit", thin !important;
  letter-spacing: 1.25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* ... */

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  /* background-color: #272727; */
}
nav.navbar.scrolled {
  padding: 0px 0;
}
nav.navbar a.navbar-brand {
  width: 5%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  letter-spacing: 0.8px;
  /* padding: 0 25px; */
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(135, 135, 135, 0.81);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(147, 147, 147, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  border: 1px solid #424242;
  /* padding: 18px 34px; */
  font-size: 18px;
  /* margin-left: 18px; */
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 20px;
}

.navbar-text button a {
  padding: 18px 34px;
  border-radius: 20px;
}
.navbar-text button span {
  z-index: 1;
  border-radius: 20px;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 20px;
}
.navbar-text button a:hover {
  color: #ffffff;
  background-color: #000;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #000000;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #1c1c1c;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #3f3f3f;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #202020;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #000000;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

nav.navbar .dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: fit-content;
  padding: 0;
  margin: 0;
  z-index: 8;
  border-radius: 0px;
  background-color: white;
  border: none;
}

nav.navbar .dropdown .dropdown-item {
  /* color: white; */
  padding: 8px 0;
}

nav.navbar .dropdown .dropdown-item:hover {
  color: darkgoldenrod;
  background-color: transparent;
}

/* end */

/* Page not found */
.page_not_found {
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 100px 0 100px 0;
  /* background-image: url("./assets/images/banner-bg.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; */
}

.banner h1 {
  font-size: 75px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  text-align: left;
}
.banner button {
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  border: 1px solid white;
  padding: 16px;
  border-radius: 20px;
}

.banner button a {
  color: brown;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/* end */

/* about */

#about {
  text-align: left;
}

/************ Contact Css ************/
.contact {
  /* background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%); */
  padding: 60px 0 100px 0;
  background-color: #272727;
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgb(126 126 126 / 10%);
  border: 1px solid rgb(142 142 142 / 50%);
  border-radius: 10px;
  color: #343434;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #949494;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/* services */

#services {
  text-align: left;
}

/* our work */

.ourwork {
  margin-top: 0;
  padding: 100px 0 100px 0;
  /* background-image: url("./assets/images/background2.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; */
}

.project_box {
  text-align: left;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 1px 2px 3px gray;
  margin: 10px;
  width: 30% !important;
}

.project_box img {
  cursor: pointer;
  margin: 10px 0;
}

.project_box img:hover {
  transform: scale(1.02, 1.02);
}

/* iframes */

.i_frame_body {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  backface-visibility: hidden;
  outline: none !important;
  background-color: #000000ab;
  cursor: pointer;
  display: none !important;
}

.i_frame_show {
  display: block !important;
}

iframe {
  border: none;
}

.i_frame_spec {
  position: absolute;
  display: block;
  top: 2% !important;
  left: 10% !important;
  width: 80% !important;
  height: 80% !important;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 8px;
  background: rgb(0, 0, 0);
}

.i_frame_close {
  position: absolute;
  right: 10px;
  top: 5px;
  color: white;
  cursor: pointer;
  z-index: 99999;
}
/* sme - salesforge */

.sme-salesforge {
  text-align: left;
}

.sme-salesforge .banner {
  background: none;
  padding: 11% 0 100px 0;
}

.sme-salesforge .sme-content {
  padding-right: 20%;
}

.sme-salesforge .sme-content li .fw-bold {
  margin-bottom: 0.5rem;
}

.sme-salesforge h2 {
  font-size: 20px;
  color: rgb(235, 151, 41);
}

.sme-salesforge h5 {
  font-size: 18px;
}

.sme-salesforge .sme-content li {
  background-color: transparent;
  /* color: white; */
  border: none;
}

/* new css */

footer {
  text-align: left;
}

@media (max-width: 992px) {
  /* nav.navbar {
    background-color: #f7f7f7;
  } */

  .navbar-collapse .navbar-nav {
    text-align: justify;
  }

  .banner h1 {
    font-size: 55px;
  }

  .i_frame_body .ratio-16x9 {
    --bs-aspect-ratio: 62.25%;
  }
}

@media (max-width: 768px) {
  .i_frame_body .ratio-16x9 {
    --bs-aspect-ratio: 81.25%;
  }
}

@media (max-width: 481px) {
  nav.navbar .navbar-nav .nav-link.navbar-link {
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  }
  .container .row {
    margin-left: 0;
    margin-right: 0;
  }

  nav.navbar {
    padding: 10px 0px;
  }

  nav.navbar a.navbar-brand {
    width: 12%;
    margin: 0;
  }

  .sme-salesforge .banner {
    padding: 26% 0 100px 0;
  }

  .sme-salesforge .sme-content {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }

  .banner h1 {
    font-size: 35px;
  }

  .project_box {
    width: 100% !important;
  }

  /* .i_frame_spec {
    top: 10% !important;
  }
  .i_frame_body .ratio-16x9 {
    --bs-aspect-ratio: 170%;
  } */

  .i_frame_show {
    display: none !important;
  }
}
