body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* new light theme css */
/* Custom animations */
@keyframes text-reveal {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.text-reveal-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.text-reveal-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #fff;
  animation: text-reveal 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

.heading-text {
  position: relative;
  overflow: hidden;
}

.heading-text::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  animation: slide-left 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  transform-origin: right;
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.glass-card {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
}

.hover-lift {
  transition: transform 0.3s, box-shadow 0.3s;
}

.hover-lift:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Fade-up animation */
@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-up {
  animation: fade-up 0.7s ease-out forwards;
}

/* Slide-in-right animation */
@keyframes slide-in-right {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slide-in-right {
  animation: slide-in-right 0.7s ease-out forwards;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* Account for fixed navbar */
}

body {
  padding-top: 76px; /* Add padding to account for the fixed navbar height */
}

/* Override Bootstrap primary color */
:root {
  --bs-primary: #0d6efd;
  --bs-primary-rgb: 13, 110, 253;
}

.bg-primary-light {
  background-color: rgba(var(--bs-primary-rgb), 0.05);
}

.text-primary {
  color: var(--bs-primary) !important;
}

.border-primary {
  border-color: var(--bs-primary) !important;
}

.border-primary-subtle {
  border-color: rgba(var(--bs-primary-rgb), 0.3) !important;
}

/* Custom spinner */
.spinner {
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Gradient text */
.text-gradient {
  background: linear-gradient(90deg, var(--bs-primary) 0%, #4d6eff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* Add Animate.css styles */
.animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate__delay-1s {
  animation-delay: 0.2s;
}

.animate__delay-2s {
  animation-delay: 0.4s;
}

.animate__delay-3s {
  animation-delay: 0.6s;
}

.animate__delay-4s {
  animation-delay: 0.8s;
}

/* Transitions */
.transition-all {
  transition: all 0.3s ease;
}

/* Card hover effects */
.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

/* end */
