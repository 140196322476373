.blog-container {
  padding-top: 78px;
  font-size: 21px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.blog-details {
  justify-content: space-between;
}

/* .text-muted {
  color: rgb(193, 193, 193) !important;
} */

.article-content {
  text-align: left;
}

.article-content h2 {
  font-size: 24px;
  color: azure;
}

.article-content h3 {
  font-size: 19px;
}

.comment .d-flex {
  justify-content: center;
}

@media (max-width: 481px) {
  .blog-details {
    flex-direction: column;
  }
  .blog-container {
    padding-top: 45px;
  }
}
